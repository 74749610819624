import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
	// storageKeys = {
	//   PENDING_SECTION: 'INPUT_GUARD_PENDING_SECTION',
	// };

	constructor(private authService: LoginService, public router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const isUserLogged = this.authService.isLogged();

		if (!isUserLogged) {
			this.router.navigate(['/login']);
		}
		return isUserLogged;
	}
}
