import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { apiUrl } from '../api-url';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  apiUrl = apiUrl;

  public userRole = new BehaviorSubject<any>(null);
  public menuItems = new BehaviorSubject<any>(null);

  constructor(
    private _http: HttpClient,
    private router: Router) {}


    login(email: string, password: string) {
      let params: any;
      params = {
        email: String(email || ''),
        password: String(password || '')
      };
      return this._http
        .post(this.apiUrl + 'content-user/in', params )
        .pipe(map((data: any) => {
              if(!data.error){
                this.userRole.next(data.user.role);
                sessionStorage.setItem('Dimo_UserId', data.user._id);
                sessionStorage.setItem('Dimo_sessionToken', data.user.session_token);
                sessionStorage.setItem('Dimo_role', data.user.role);
                sessionStorage.setItem('Dimo_fullName', data.user.fullName);
                return data;
              }else{
                return data;
              }
            },
            (error: any) => {}
          )
        );
    }

    forgotPassword(email:string){
      let params = new HttpParams()
			.set('email', String(email))

      return this._http.get(apiUrl + `content-user/forgot`, {params} )
      .pipe(map((data: any) => {
          return data;
        })
      );
    }

    setPassword(email:string, token:string, password: string){
      let params = new HttpParams()
			.set('email', String(email))
			.set('token', String(token))
      .set('password', String(password));

      return this._http.post(apiUrl + `content-user/password`, params )
      .pipe(map((data: any) => {
          return data;
        })
      );
    }

    isLogged() {
      return sessionStorage.getItem('Dimo_UserId') !== null
      && sessionStorage.getItem('Dimo_sessionToken') != null
    }

    logOut() {
      this.userRole.next(null);
      sessionStorage.clear();
      this.router.navigate(['/login']);
    }

}
