import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './api/auth/authentication.guard';

const routes: Routes = [
  {
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'app',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AuthenticationGuard]
  },
  {
		path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then(m => m.ForgotModule),
  },
  {
    path: '**',
    redirectTo: 'app'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
