import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
	template: '<router-outlet></router-outlet>',
  styleUrls: []
})
export class AppComponent {
  title = 'dimo-cms';

}
