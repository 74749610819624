import { HttpInterceptor, HttpHeaders, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler) {

      const authorizedRequest = req.clone({
        setHeaders:{
          'session-token': String(sessionStorage.getItem('Dimo_sessionToken') || ''),
          'user-id': String(sessionStorage.getItem('Dimo_UserId') || '')
        }
      });

      return next.handle(authorizedRequest);
    }

  }
